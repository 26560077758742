<template>
  <v-container :fluid="true" class="synapsa-container">
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 synapsa-tabs">
        <v-btn
          v-for="tab in tabs"
          v-permission="tab.permissions"
          :key="tab.id"
          :text="tab.id !== activeTab"
          :color="tab.id === activeTab ? 'primary' : ''"
          :outlined="tab.id === activeTab"
          small
          class="text-capitalize synapsa-tab"
          :class="tab.id === activeTab ? 'elevation-1' : ''"
          @click="to(tab)"
        >
          {{ $t(tab.name) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import permission from "@/directive/permission/permission.js";
export default {
  directives: { permission },
  data() {
    return {
      activeTab: "secPolicyAlert",
      tabs: [
        {
          id: "secPolicyAlert",
          name: "awaitingApproval.secPolicy",
          route: "security_policy",
          permissions: ["view awaitingsecuitypolicy"],
        },
        {
          id: "dataFeedsAlert",
          name: "awaitingApproval.dataFeeds",
          route: "data_feeds",
          permissions: ["view awaitingdatafeed"],
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeTab = newValue.name;
      },
    },
  },
  methods: {
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
  },
};
</script>
